import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
	selector: 'app-header-th',
	templateUrl: './header-th.component.html',
	styleUrls: ['./header-th.component.scss']
})
export class HeaderThComponent {
	@Input() dataConfigType: any;
	@Input() dictionaryList: Map<string, any[]>;
	@Input() dictionaryFilter: Map<string, any>;
	@Input() itemList: any[];
	@Input() td: any;

	@Output() checkSelectedClass = new EventEmitter<[any[], string]>();
	@Output() onNodeFilter = new EventEmitter<[any[], string, string, string]>();
	@Output() onDateChange = new EventEmitter<[any, string, string]>();
	@Output() onStringFilter = new EventEmitter<[any, string]>();

	constructor(
		public changeDetectorRef: ChangeDetectorRef,
	) {}
}
