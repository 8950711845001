<div *ngIf="isUseTitle">{{ td.title }}:&nbsp;</div>

<div
	*ngIf="!dataService.dtoTransfer.toDeleteNomList.includes(item.id)"
	[ngSwitch]="td.type"
	(click)="td.isNotClickable? null: editMode.emit(item.id)"
	class="pointer"
>
	<div *ngSwitchCase="dataConfigType.id">
		<div *ngIf="dictionaryMap
				&& dictionaryMap.has(td.name)
				&& dictionaryMap.get(td.name).has(item[td.name])"
		>
			<div
				*ngIf="!td.fildName"
			> {{ dictionaryMap.get(td.name).get(item[td.name]).name }}
			</div>
			<div
				*ngIf="td.fildName"
			> {{ dictionaryMap.get(td.name).get(item[td.name])[td.fildName] }}
			</div>
			<!--{{dictionaryMap.get(td.name).get(item[td.name]).name}}-->
		</div>
	</div>

	<div *ngSwitchCase="dataConfigType.string">
		{{ item[td.name] }}
	</div>
	<div *ngSwitchCase="dataConfigType.dataOut">
		{{ createDate.emit(item[td.name]) }}
	</div>
	<div *ngSwitchCase="dataConfigType.stringIn">
		<input
			class="form-control form-control-sm list-inline"
			(change)="changeDetect.emit()"
			[(ngModel)]="item[td.name]"
		/>
	</div>
	<div *ngSwitchCase="dataConfigType.numeric">
		<input
			type="number"
			(change)="changeDetect.emit()"
			class="form-control form-control-sm list-inline"
			[(ngModel)]="item[td.name]"
		/>
	</div>
	<div *ngSwitchCase="dataConfigType.boolean">
		<app-checkbox [(checked)]="item[td.name]"></app-checkbox>
	</div>
</div>
