<div class="row justify-content-center">
	<app-button
		class="g-button-secondary-square g-col-md-1"
		(click)="setAllItems(true)"
	>
		Все
	</app-button>
	<app-button
		class="g-button-secondary-square g-col-md-1"
		(click)="setAllItems(false)"
	>
		Снять
	</app-button>
</div>

<input
	type="text"
	class="form-control size-sm full-width"
	id="filter"
	#filter
	(keyup)="filterItems(filter.value)"
	placeholder="Фильтр..."
/>

<cdk-virtual-scroll-viewport
	*ngIf="items.length"
	[style.height]="'200px'"
	[style.width]="'500px'"
	itemSize="50"
	minBufferPx="400"
	maxBufferPx="800"
>
	<app-checkbox
		*cdkVirtualFor="let item of filteredItems"
		[(checked)]="item.isChecked"
		(change)="emitAllSelectedItems()"
	>{{ item.name }}
	</app-checkbox>
</cdk-virtual-scroll-viewport>

