<app-header-th
	*ngFor="let td of tableDataConf"
	[td]="td"
	[dataConfigType]="dataConfigType"
	[dictionaryList]="dictionaryList"
	[dictionaryFilter]="dictionaryFilter"
	[itemList]="itemList"
	(checkSelectedClass)="checkSelectedClass.emit($event)"
	(onNodeFilter)="onNodeFilter.emit($event)"
	(onDateChange)="onDateChange.emit($event)"
	(onStringFilter)="onStringFilter.emit($event)"
></app-header-th>

<div class="g-padding-h g-padding-bottom">
	<div
		*ngFor="let item of itemList"
		class="g-card"
	>
		<div *ngFor="let td of tableDataConf">
			<app-body-td
				[item]="item"
				[td]="td"
				[dataConfigType]="dataConfigType"
				[dictionaryMap]="dictionaryMap"
				[dataService]="dataService"
				[isUseTitle]="true"
				(changeDetect)="changeDetect.emit()"
				(createDate)="createDate.emit($event)"
				(editMode)="editMode.emit($event)"
			></app-body-td>
		</div>
	</div>
</div>

