import {Injectable} from '@angular/core';
import {NotifierService} from 'angular-notifier';

import {IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {MesCounterparty} from '@store/data/plan/mes-counterparty';
import {DataConfig, DataConfigType} from '@shared/components/dto-viewer/models/data-config';
import {DtoService} from '@shared/components/dto-viewer/services/dto-service';
import {Store} from '@store/data/plan/store/store';
import {SdwebRepository} from '@shared/repositories/sdweb.repository';
import {EntityConstantEnums} from '@shared/constants/entity-constant.enums';
import {NgxUiLoaderService} from 'ngx-ui-loader';

@Injectable({
	providedIn: 'root'
})
export class StoreService implements IDataServiceInterface {
	activeItem: Store;
	itemList: Store[] = [];
	itemMap: Map<number, Store> = new Map<number, Store>();

	activeDataConf: DataConfig[] = [];
	tableDataConf: DataConfig[] = [];

	dictionaryMap: Map<string, Map<number, any>>;
	dictionaryList: Map<string, any[]> = new Map<string, any[]>();

	dtoTransfer: DtoService;

	constructor(
		private _mesApi: SdwebRepository,
		private _notifierService: NotifierService,
		private _loaderService: NgxUiLoaderService
	) {
		this.dtoTransfer = new DtoService(
			this, EntityConstantEnums.store, _notifierService, _loaderService, _mesApi
		);

		this.asyncUpdate();

		this.activeDataConf.push(
			new DataConfig('id', 'id', DataConfigType.string, true)
		);
		this.activeDataConf.push(
			new DataConfig('number', 'Номер склада', DataConfigType.numeric)
		);
		this.activeDataConf.push(
			new DataConfig('name', 'Название', DataConfigType.string)
		);
		this.activeDataConf.push(
			new DataConfig('description', 'Описание', DataConfigType.textarea)
		);

		this.tableDataConf.push(
			new DataConfig('number', 'Номер склада', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('name', 'Название', DataConfigType.string)
		);
		this.tableDataConf.push(
			new DataConfig('description', 'Описание', DataConfigType.string)
		);
	}

	public asyncCheck(): void {
		this.dtoTransfer.asyncCheck();
	}

	public asyncUpdate(): void {
		this.dtoTransfer.asyncUpdate();
	}

	public saveActiveItem(data: MesCounterparty): void {
		this.dtoTransfer.save([data]);
	}

	public deleteActiveItem(ids: number[]): void {
		this.dtoTransfer.delete(ids);
	}

}
