import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild,} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';

import {ExcelService} from '@shared/ui-components/table/services/excel.service';
import {ReportRepository} from '@shared/repositories/report.repository';
import {IPagination} from '@shared/models/common-types';
import {IDataRefService, IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {DtoTableComponent} from '@shared/components/dto-viewer/modules/dto-container/components/dto-table/dto-table.component';
import {DataConfig, DataConfigType} from '@shared/components/dto-viewer/models/data-config';
import {ApiRepository} from '@shared/repositories/api.repository';
import {NotifierService} from 'angular-notifier';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {IStoreNomenclatureFilter} from '../../../../../modules/nomenclature-store/models/nomenclature-interface';
import {first} from 'rxjs/operators';

@UntilDestroy()
@Component({
	selector: 'app-dto-container',
	templateUrl: './dto-container.component.html',
	styleUrls: ['./dto-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DtoContainerComponent implements OnChanges {
	@Input() dataService: IDataServiceInterface;
	@Input() dataRefService: IDataRefService;
	@Input() noItemListUpdate = false;
	@Input() itemList: any[] = [];

	@Output() edit = new EventEmitter();

	@ViewChild('table') el: ElementRef;
	@ViewChild(DtoTableComponent) dtoTableComponent: DtoTableComponent;

	dataConfigType = DataConfigType;
	isChangeDetect = true;
	itemMapMemory: any;
	itemMap: Map<number, any>;

	tableDataConf: DataConfig[]; // Описание данных на таблице

	// Связанные данные для выбора конкрентного значения
	dictionaryMap: Map<string, Map<any, any>>;
	// Связанные данные для предоставления списка для выбора
	public dictionaryList: Map<string, any[]>;

	bsConfig: Partial<BsDatepickerConfig>;

	public dictionaryFilter: Map<string, any> = new Map<string, any>();

	pagination: IPagination = {
		totalElement: 0,
		totalPages: 0,
		limit: 100,
		page: 1,
	};

	constructor(
		public cdr: ChangeDetectorRef,
		public api: ApiRepository,
		private _reportRepository: ReportRepository,
		public notifier: NotifierService,
		private loaderService: NgxUiLoaderService,
		private excelService: ExcelService
	) {
	}

	ngOnChanges() {
		this.dataService.asyncCheck();
		// this.itemList =  this.dataService.itemList;
		if (!this.noItemListUpdate) {
			this.itemList = Object.values({...this.dataService.itemList});
		}

		this.itemMap = this.dataService.itemMap;
		this.tableDataConf = this.dataService.tableDataConf;
		this.dictionaryMap = this.dataService.dictionaryMap;
		this.dictionaryList = this.dataService.dictionaryList;

		if (!this.noItemListUpdate && this.dataService.dtoTransfer) {
			this.dataService.dtoTransfer.refresh
				.pipe(untilDestroyed(this))
				.subscribe(value => {
					this.itemList = Object.values({
						...this.dataService.itemList,
					});
					this.itemMap = this.dataService.itemMap;
					this.tableDataConf = this.dataService.tableDataConf;
					this.dictionaryMap = this.dataService.dictionaryMap;
					this.dictionaryList = this.dataService.dictionaryList;
					this.cdr.markForCheck();

					this.updatePagintaionData();
				});
		}
		this.tableDataConf.forEach(value =>
			this.dictionaryFilter.set(value.name, null)
		);
	}

	updatePagintaionData() {
		this.pagination.totalElement = this.itemList.length;
		this.pagination.totalPages =
			Math.ceil(this.itemList.length / this.pagination.limit);
	}

	getItemListAppliedPagination(itemList: any[]) {
		const {limit, page} = this.pagination;

		const start = (page - 1) * limit;
		const end = start + limit;

		return itemList.slice(start, end);
	}

	pageChanged(page: number) {
		if (!page) {
			return;
		}

		if (page > this.pagination.totalPages || page < 1) {
			return;
		}

		this.pagination.page = page;
		this.cdr.markForCheck();
	}

	editMode(id: number) {
		this.dataService.activeItem = this.dataService.itemMap.get(id);

		this.dataService.dtoTransfer.refresh.emit(true);
		this.edit.emit(this.dataService.activeItem);
	}

	createTechprocess() {
		this.dataService.activeItem = {};

		this.edit.emit(this.dataService.activeItem);
	}

	createReport() {
		let hasValues = false;
		// @ts-ignore
		for (const innerMap of this.dictionaryFilter.values()) {
			if (innerMap?.size > 0) {
				hasValues = true;
				break;
			}
		}
		if (hasValues === false) {
			this.notifier.notify('success', 'Построена выгрузка данных.');
			this.exportTable();
			return;
		}
		const convMap = {};
		this.dictionaryFilter.forEach((val: any[], key: string) => {
			if (val instanceof Map) {
				const convInnerMap = {};
				val.forEach((val: any[], key: number) => {
					convInnerMap[key] = val;
				});
				convMap[key] = convInnerMap;
			} else {
				convMap[key] = val;
			}
		});
		const filterReport: IStoreNomenclatureFilter = {
			entityConstant: this.dataService.dtoTransfer.entityConstant,
			filter: convMap,
		};
		this.loaderService.start();
		this._reportRepository
			.getReport(filterReport)
			.pipe(first())
			.subscribe(res => {
				if (res.error !== null) {
					this.notifier.notify('warning', res.error);
				}
				this.loaderService.stop();
				this.cdr.markForCheck();
			});
	}

	exportTable(): void {
		// Прежде, чем отправять в файл надо раставить правильно табуляцию

		const table: HTMLTableElement =
			this.dtoTableComponent.tableRef.nativeElement;
		const rows: string[] = table.innerText.split('\n');
		let resString: string = '';
		let lastRow: string = '';

		rows.forEach(row => {
			if (
				!lastRow.includes('\t') &&
				!row.includes('\t') &&
				row.length > 0
			) {
				resString += '\n';
			}
			resString += row;
			lastRow = row;
		});

		// Вызовите сервис для экспорта таблицы в Excel
		this.excelService.exportToExcelByInnerText(resString, 'table_data');
	}

	createDate(date: Date) {
		if (date) {
			return new Date(date).toLocaleString('ru');
		} else {
			return '';
		}
	}

	changeDetect() {
		this.isChangeDetect = true;
	}

	addNew(id: number) {
		this.dataRefService.addNew(id);

		this.itemList = Object.values({...this.dataService.itemList});
	}

	markToDelete(item) {
		this.dataService.dtoTransfer.markToDelete(item);

		this.itemList = Object.values({...this.dataService.itemList});
	}

	/**
	 * По значению в поле заносим значение в фильтры
	 * @param node
	 * @param filterFild
	 * @param filterValue
	 * @param name
	 */
	onNodeFilter(
		node: any[],
		filterFild: string,
		filterValue: string,
		name: string
	) {
		if (!filterFild) {
			filterFild = 'name';
		}

		this.dictionaryFilter.set(name, new Map<number, any>());

		node.forEach(n => {
			if (
				n[filterFild].toLowerCase().includes(filterValue.toLowerCase())
			) {
				n.isChecked = true;
				this.dictionaryFilter.get(name).set(n.id, n);
			} else {
				n.isChecked = false;
			}
		});

		this.checkDisplayRow();
	}

	/***
	 * Заносим в фильтры выбранные группы
	 * @param list
	 * @param name
	 */
	checkSelectedClass(list: any[], name: string) {
		this.dictionaryFilter.set(name, new Map<number, any>());

		list.forEach(value =>
			this.dictionaryFilter.get(name).set(value.id, value)
		);

		this.checkDisplayRow();
	}

	/***
	 * Добавляем в фильтры даты в рамках которых будут фильтроваться поля
	 * @param event
	 * @param fild
	 * @param key
	 */
	onDateChange(event: Date, fild: string, key: string) {
		if (!this.dictionaryFilter.get(key)) {
			this.dictionaryFilter.set(key, {});
		}

		this.dictionaryFilter.get(key)[fild] = event;

		this.checkDisplayRow();
	}

	/***
	 * Добавляем в фильтры стринговое заначение
	 * @param event
	 * @param key
	 */
	onStringFilter(event, key: string) {
		this.dictionaryFilter.set(key, event);
		this.checkDisplayRow();
	}

	/***
	 *
	 * @param value
	 * @param key
	 */
	checkDisplayRow() {
		if (!this.itemMapMemory && this.noItemListUpdate) {
			this.itemMapMemory = Object.values({...this.itemList});
		}
		this.itemList = [];

		// Для первого раза используем this.dataService.
		// itemList, для последующих this.itemMapMemory
		for (const item of this.noItemListUpdate
			? this.itemMapMemory
			: this.dataService.itemList) {
			let insert = true;

			for (const tdc of this.tableDataConf) {
				// Если фильтр пустой или значение = null - то пропускаем проверку
				if (!this.dictionaryFilter.get(tdc.name) || !item[tdc.name]) {
					continue;
				}

				// Для типа данных id
				if (tdc.type === DataConfigType.id) {
					if (
						!this.dictionaryFilter
							.get(tdc.name)
							.has(item[tdc.name]) ||
						this.dictionaryFilter.get(tdc.name).size === 0
					) {
						insert = false;

						continue;
					}
				}

				// Для типа данных string или number
				if (
					(tdc.type === DataConfigType.string ||
						tdc.type === DataConfigType.numeric) &&
					this.dictionaryFilter.get(tdc.name).length !== 0
				) {
					if (
						!item[tdc.name]
							.toString()
							.toLowerCase()
							.includes(
								this.dictionaryFilter
									.get(tdc.name)
									.toLowerCase()
							)
					) {
						insert = false;
						continue;
					}
				}

				// Для типа данных date
				if (
					tdc.type === DataConfigType.dataOut ||
					tdc.type === DataConfigType.dataIn
				) {
					if (
						this.dictionaryFilter.get(tdc.name).start &&
						new Date(item[tdc.name]).getTime() <
						new Date(
							this.dictionaryFilter.get(tdc.name).start
						).getTime()
					) {
						insert = false;
						continue;
					}
					if (
						this.dictionaryFilter.get(tdc.name).end &&
						new Date(item[tdc.name]).getTime() >
						new Date(
							this.dictionaryFilter.get(tdc.name).end
						).getTime()
					) {
						insert = false;
						continue;
					}
				}
			}

			if (insert) {
				this.itemList.push(item);
			}
		}

		this.updatePagintaionData();
	}
}
