<app-table-buttons-header
	[dataRefService]="dataRefService"
	[isChangeDetect]="isChangeDetect"
	(createTechprocess)="createTechprocess()"
	(createReport)="createReport()"
	(addNew)="addNew($event)"
></app-table-buttons-header>

<div class="g-flex-grow-1 g-overflow-visible">
	<app-dto-table
		*appAdaptive="{min: 768}"
		class="g-height-100"
		[dataRefService]="dataRefService"
		[tableDataConf]="tableDataConf"
		[dataConfigType]="dataConfigType"
		[dictionaryList]="dictionaryList"
		[dictionaryFilter]="dictionaryFilter"
		[itemList]="getItemListAppliedPagination(itemList)"
		[dictionaryMap]="dictionaryMap"
		[dataService]="dataService"
		(checkSelectedClass)="checkSelectedClass($event[0], $event[1])"
		(onNodeFilter)="onNodeFilter($event[0], $event[1], $event[2], $event[3])"
		(onDateChange)="onDateChange($event[0], $event[1], $event[2])"
		(onStringFilter)="onStringFilter($event[0], $event[1])"
		(changeDetect)="changeDetect()"
		(createDate)="createDate($event)"
		(editMode)="editMode($event)"
		(markToDelete)="markToDelete($event)"
	></app-dto-table>

	<app-dto-list
		*appAdaptive="{max: 768}"
		class="g-height-100"
		[dataRefService]="dataRefService"
		[tableDataConf]="tableDataConf"
		[dataConfigType]="dataConfigType"
		[dictionaryList]="dictionaryList"
		[dictionaryFilter]="dictionaryFilter"
		[itemList]="getItemListAppliedPagination(itemList)"
		[dictionaryMap]="dictionaryMap"
		[dataService]="dataService"
		(checkSelectedClass)="checkSelectedClass($event[0], $event[1])"
		(onNodeFilter)="onNodeFilter($event[0], $event[1], $event[2], $event[3])"
		(onDateChange)="onDateChange($event[0], $event[1], $event[2])"
		(onStringFilter)="onStringFilter($event[0], $event[1])"
		(changeDetect)="changeDetect()"
		(createDate)="createDate($event)"
		(editMode)="editMode($event)"
		(markToDelete)="markToDelete($event)"
	></app-dto-list>
</div>

<div
	*ngIf="itemList?.length"
	class="g-margin-top g-center-h"
>
	<app-pagination
		rangePosition="none"
		[totalItem]="pagination.totalElement"
		[pageSize]="pagination.limit"
		[currentPage]="pagination.page"
		(change)="pageChanged($event)"
	></app-pagination>
</div>

