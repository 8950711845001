import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IDataRefService, IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {DataConfig} from '@shared/components/dto-viewer/models/data-config';

@Component({
	selector: 'app-dto-list',
	templateUrl: './dto-list.component.html',
	styleUrls: ['./dto-list.component.scss']
})
export class DtoListComponent {
	@Input() dataRefService: IDataRefService;
	@Input() tableDataConf: DataConfig[];
	@Input() dataConfigType: any;
	@Input() dictionaryList: Map<string, any[]>;
	@Input() dictionaryFilter: Map<string, any>;
	@Input() itemList: any[];
	@Input() dictionaryMap: Map<string, Map<any, any>>;
	@Input() dataService: IDataServiceInterface;

	@Output() checkSelectedClass = new EventEmitter<[any[], string]>();
	@Output() onNodeFilter = new EventEmitter<[any[], string, string, string]>();
	@Output() onDateChange = new EventEmitter<[any, string, string]>();
	@Output() onStringFilter = new EventEmitter<[any, string]>();
	@Output() changeDetect = new EventEmitter();
	@Output() createDate = new EventEmitter<Date>();
	@Output() editMode = new EventEmitter<number>();
	@Output() markToDelete = new EventEmitter<any>();
}
