import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IDataRefService} from '@shared/components/dto-viewer/models/data-service-interface';

@Component({
	selector: 'app-table-buttons-header',
	templateUrl: './buttons-header.component.html',
	styleUrls: ['./buttons-header.component.scss']
})
export class ButtonsHeaderComponent {
	@Input() dataRefService: IDataRefService;
	@Input() isChangeDetect: boolean;

	@Output() createTechprocess = new EventEmitter();
	@Output() createReport = new EventEmitter();
	@Output() addNew = new EventEmitter<number>();
}
