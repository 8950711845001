import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IDataServiceInterface} from '@shared/components/dto-viewer/models/data-service-interface';
import {DataConfig} from '@shared/components/dto-viewer/models/data-config';

@Component({
	selector: 'app-body-td',
	templateUrl: './body-td.component.html',
	styleUrls: ['./body-td.component.scss'],
})
export class BodyTdComponent {
	@Input() item: any;
	@Input() td: DataConfig;
	@Input() isUseTitle = false;
	@Input() dataConfigType: any;
	@Input() dataService: IDataServiceInterface;
	@Input() dictionaryMap: Map<string, Map<any, any>>;

	@Output() changeDetect = new EventEmitter();
	@Output() createDate = new EventEmitter<Date>();
	@Output() editMode = new EventEmitter<number>();
}
