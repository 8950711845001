import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TItem} from '@shared/ui-components/list-selector-items/models/list-selector-items.types';

@Component({
	selector: 'app-list-selector-items',
	templateUrl: './list-selector-items.component.html',
	styleUrls: ['./list-selector-items.component.scss']
})
export class ListSelectorItemsComponent implements OnChanges {
	@Input() items: TItem[] = [];
	@Input() displayField = 'name';
	@Output() onSelectedItems = new EventEmitter<TItem[]>();

	public filteredItems: TItem[];

	constructor() {
		this.filteredItems = [];
	}

	public ngOnChanges(changes: SimpleChanges): void {
		this.filteredItems = this.items;
	}

	get allSelectedItems(): TItem[] {
		return [...this.filteredItems].filter(i => i.isChecked);
	}

	public emitAllSelectedItems(items?: TItem[]): void {
		this.onSelectedItems.emit(items || this.allSelectedItems);
	}

	public setAllItems(flat: boolean): void {
		this.filteredItems.forEach(i => (i.isChecked = flat));

		this.emitAllSelectedItems(flat ? this.filteredItems : null);
	}

	public filterItems(text: string): void {
		if (!text) {
			this.filteredItems = this.items;

			return;
		}

		text = text.toLowerCase();

		this.filteredItems = [...this.items].filter(
			(i) => i.name.toLowerCase().includes(text)
		);
	}
}
