<div class="header-buttons">
	<app-button
		*ngIf="!dataRefService"
		class="g-button-secondary-empty font-size-16"
		matTooltip="Добавить"
		svgIcon="add-circle-outline"
		(click)="createTechprocess.emit()"
	>
	</app-button>

	<app-button
		*ngIf="!dataRefService"
		class="g-button-success-empty font-size-16"
		matTooltip="Excel"
		(click)="createReport.emit()"
	>
		Excel
	</app-button>

	<app-button
		*ngIf="dataRefService"
		class="g-button-secondary-empty"
		matTooltip="Сохранить"
		svgIcon="save-alt"
		(click)="dataRefService.saveAllChanges()"
		[disabled]="!isChangeDetect"
	>
	</app-button>

	<ngx-select
		*ngIf="dataRefService"
		[items]="dataRefService.addList"
		optionTextField="name"
		optionValueField="id"
		(select)='addNew.emit($event)'
		[placeholder]="'Выберите для привязки'"
	>
		<ng-template
			ngx-select-option-selected
			ngx-select-option
			let-option
			let-text="text"
		>
			{{option.data.partnumber}} - {{option.data.name}} - {{option.data.description}}
		</ng-template>

		<ng-template
			ngx-select-option-not-found
			let-input
		>
			Нет совпадений
		</ng-template>
	</ngx-select>
</div>
