<div *ngIf="td.type === dataConfigType.id && !!dictionaryList">
	<mat-menu #popoverId="matMenu">
		<div
			class="popoverData"
			appStopPropagation
		>
			<div class="popover-head">
				Фильтр:  {{td.title}}
			</div>

			<app-list-selector-items
				*ngIf="dictionaryList?.get(td?.name)?.length > 0"
				[items]="dictionaryList.get(td.name)"
				(onSelectedItems)="checkSelectedClass.emit([$event, td.name])"
			></app-list-selector-items>
		</div>
	</mat-menu>

	<div class="container-filter">
		<input
			type="text"
			class="form-control container-filer__input"
			#filter
			(keyup)="onNodeFilter.emit([dictionaryList.get(td.name), td.fildName, filter.value, td.name])"
			placeholder="Фильтр..."/>
		<button
			type="button"
			class='btn btn-sm btn-outline-secondary container-filter__button'
			title="Фильтр"
			[matMenuTriggerFor]="popoverId"
		>
						<span
							*ngIf="!dictionaryFilter.get(td.name)"
						><app-icon name="filter"></app-icon></span>
			<span
				*ngIf="dictionaryFilter.get(td.name)"
			>{{dictionaryFilter.get(td.name).size}}</span>
		</button>
	</div>

</div>

<div *ngIf="td.type === dataConfigType.dataOut">
	<mat-menu #popoverData="matMenu">
		<div
			class="popoverData"
			appStopPropagation
		>
			<div class="popover-head">
				Фильтр по дате:  {{td.title}}
			</div>
			<mat-form-field appearance="outline">
				<mat-label>Дата начала</mat-label>
				<input
					matInput
					[matDatepicker]="pickerStart"
					(dateChange)="onDateChange.emit([$event.value, 'start', td.name])"
					[(ngModel)]="!dictionaryFilter[td.name] ? null : dictionaryFilter[td.name].start"
				>
				<mat-datepicker-toggle
					matSuffix
					[for]="pickerStart"
				></mat-datepicker-toggle>
				<mat-datepicker #pickerStart></mat-datepicker>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Дата конца</mat-label>
				<input
					matInput
					[matDatepicker]="pickerEnd"
					(dateChange)="onDateChange.emit([$event.value, 'end', td.name])"
					[(ngModel)]="!dictionaryFilter[td.name]? null :dictionaryFilter[td.name].end"
				>
				<mat-datepicker-toggle
					matSuffix
					[for]="pickerEnd"
				></mat-datepicker-toggle>
				<mat-datepicker #pickerEnd></mat-datepicker>
			</mat-form-field>
		</div>
	</mat-menu>

	<button
		type="button"
		class='btn btn-sm btn-outline-secondary small_btn full-width'
		title="Фильтр"
		[matMenuTriggerFor]="popoverData"
	>
		Фильтр
		<span *ngIf="dictionaryFilter.get(td.name) ">(
			 <span *ngIf="dictionaryFilter.get(td.name).start">
			н
			 </span>
			 <span *ngIf="dictionaryFilter.get(td.name).end">
			к
			</span>)
		</span>
	</button>
</div>

<div
	*ngIf="(td.type === dataConfigType.string)
		|| (td.type === dataConfigType.numeric)
		|| (td.type === dataConfigType.textarea)"
>
	<input
		type="text"
		class="form-control size-sm full-width"
		id="filter"
		#filter
		(keyup)="onStringFilter.emit([filter.value, td.name])"
		placeholder="Фильтр..."
	/>
</div>

<span
	class="sorting"
	appSortColumn
	[sortKey]="td.name"
	[data]="itemList"
	(click)="changeDetectorRef.detectChanges()"
>
	 {{td.title}}
</span>
