<app-table
	class="table"
	[data]="itemList"
>
	<ng-container *viewItem>
		<app-table-header>
			Номер склада
		</app-table-header>
		<app-table-item *lineValue="let item">
			<span
				class="border-bottom pointer"
				(click)="editMode.emit(item.id)"
			>{{ item.number }}</span>
		</app-table-item>
	</ng-container>
	<ng-container *viewItem>
		<app-table-header>
			Название
		</app-table-header>
		<app-table-item *lineValue="let item">
			{{ item.name }}
		</app-table-item>
	</ng-container>
	<ng-container *viewItem>
		<app-table-header>
			Описание
		</app-table-header>
		<app-table-item *lineValue="let item">
			{{ item.description }}
		</app-table-item>
	</ng-container>
</app-table>
